.main_block {
    display: flex;
    width:100%;
    font-family: 'InterLight';
     font-weight: normal;
     cursor:pointer;
  }
  .Article{
    justify-content: center;
    display: flex;
  }
  .left_block {
    width: 40%;
    height: auto;
  }
 
  .left_block img {
    width: 100%;
    height: 15rem;
    object-fit: cover;
    overflow: hidden;
    border-radius: 5px;
  }
  
  .right_block {
    width: 60%;
    height: auto;
    padding: 10px;
    padding-top: 0;
    overflow: hidden;
  }
  .right_block h2{
    margin-top:0;
    margin-bottom: 0;
  }
  
  .text {
   margin-top:0;
  }
  
  .read_more {
    font-weight: bold;
  }
  @media only screen and (max-width: 600px) {
   
    .right_block {
      height: 14rem; /* заданная высота блока */
     line-height: 1.5rem;text-overflow: ellipsis
    }
  }