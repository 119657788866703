
.myBtn {
    background-size: cover;
    border-radius: 6px;
    font-family: 'InterSemiBold';
    color:#E6E6E6;
  font-size: 1.8rem;
  text-shadow: 0px 6px 20px rgba(0, 0, 0, 0.8);
  /* identical to box height */
border:0px;
padding:0.5rem;
 width:15rem;
 height:15rem;
 background-position: center;
 background-repeat: no-repeat;
 background-size: cover;
 position: relative;transition: transform 0.2s ease-in-out;z-index:4
    } 
    @media only screen and (max-width: 600px) {
        
      .myBtn {
        width:98%;
        margin-bottom:1%;
        height:25rem;
      }
     
    }
    .blur{
      filter: blur(5px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  transition: all 0.1s;
    }
    .text_no_blur{ position: relative;
      z-index: 1;  transition: all 0.1s;}
      .myBtn:hover {  cursor:default; transform: scale(2);z-index:5} 
     
    .myBtn:active {  } /* при нажатии */
    @media only screen and (max-width: 600px) {
        
      .myBtn {
        width:98.5%;
        margin-bottom:1%;
        height:25rem;
      }
      .myBtn:hover {  cursor:default; transform: scale(1)} 
    }