
.myBtn {
    background-size: cover;
    border-radius: 6px;
    font-family: 'InterSemiBold';
    color:#E6E6E6;
  font-size: 1.8rem;
  text-shadow: 0px 1px 20px rgba(0, 0, 0, 0.8);
  /* identical to box height */
border:0px;
padding:0.5rem;
 width:15rem;
 height:15rem;
 position: relative;
    } 
    .myBtn_mob {

    }
    @media only screen and (max-width: 900px) {
      .myBtn {
        width:11rem;
        font-size: 1.1rem;
        height:11rem;
      }
      @media only screen and (max-width: 600px) {
        
        .myBtn {
          margin-top:5px;
          font-size: 1.5rem;
        }
        .myBtn_mob {
          width:94vw;
          height:20vh;
          margin-left:1%
        }
      }
    }
    .blur{
      filter: blur(5px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  transition: all 0.1s;
    }
    .text_no_blur{ position: relative;
      z-index: 1;  transition: all 0.1s;}
      .myBtn:hover {  cursor:pointer; } 
      .myBtn:hover .text_no_blur{   transform: scale(1.1);}
    .myBtn:hover .blur { backdrop-filter: blur(1px); } /* при наведении курсора мышки */
    .myBtn:active {  } /* при нажатии */